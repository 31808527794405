//一、导入
import axios from "axios";
import router from "@/router";
import { Loading, Message } from "element-ui";
//二、创建axios实例
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    // baseURL: "/api",
    // .env 全局默认配置文件，不论什么环境都会加载合并
    // .env.development 开发环境下的配置文件
    // .env.production 生产环境下的配置文件
    "content-type": "application/x-www-form-urlencoded",
    // "Content-Type": "application/json",
  },
  timeout: 5000,
});
//三、拦截器
// request拦截器，语法： axios/request.interceptors.请求/响应.use(callback, callback)
let loadingInstance = 0;
request.interceptors.request.use(
  (config) => {
    // 请求头加token
    let token = localStorage.getItem("token");
    config.headers.token = token;
    // 全局Loading
    loadingInstance = Loading.service({ fullscreen: true });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
request.interceptors.response.use(
  (response) => {
    // 成功响应
    // 关闭Loading
    if (loadingInstance) loadingInstance.close();

    // 权限判断（咱们这个项目几乎无概率
    if (response.data.status == 403) {
      Message.error("无权访问，跳转中...");
      router.push("/login");
      return;
    }
    // TOKEN过期
    if (response.data.status == 400 && response.data.meta.msg.includes("TOKEN过期")) {
      Message.error("TOKEN过期，请重新登录...");
      router.push("/login");
      // TODO. 调用store去清除登录数据
      return;
    }

    return response.data;
  },
  (error) => {
    // 失败响应
    let errorObj = JSON.parse(JSON.stringify(error));

    // 关闭Loading
    if (loadingInstance) loadingInstance.close();
    // 判断失败原因
    if (errorObj.message.includes("timeout")) {
      Message.error("网络繁忙，请刷新后重试...");
    } else if (errorObj.message.includes("404")) {
      Message.error("大兄弟，接口地址写错了 404...");
    } else if (errorObj.message.includes("canceled")) {
      console.log("重复点击取消...", errorObj);
    } else {
      // 邮件报警
      // 错误原因   error.message
      // 错误数据   请求的接口地址、当前访问的路由 JSON.stringify(error)  数据编码方式等
      // 用户信息    h5
      // 浏览器信息  ua
      let email = "15021459079@163.com";
      let title = "千锋云直播B2C管理系统异常通知";
      let userinfo = `${localStorage.getItem("uname")}（${localStorage.getItem("roleName")}）`;
      let ua = navigator.userAgent;
      let message = error.message;
      let errorinfo = JSON.stringify(error);
      fetch("http://kg.zhaodashen.cn/mt/admin/email.jsp", {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `email=${email}&title=${title}&userinfo=${userinfo}&ua=${ua}&message=${message}&errorinfo=${errorinfo}`,
      }).then((res) => {
        console.log(res);
      });
    }
    return Promise.reject(error);
  }
);
//四、导出
export default request;
