import request from "../utils/request.js";

export const getNewLableApi = (params) => {
  return request({
    url: "article/page",
    method: "get",
    params,
  });
};

export const getNewDetailApi = (params) => {
  return request({
    url: `article/detail/${params}`,
    method: "get",
  });
};
